import * as Accordion from "@radix-ui/react-accordion"
import Icon from "components/Icon"
import LinkButton from "components/buttons/LinkButton"
import { loadPage } from "library/Loader/TransitionUtils"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import type { IndustryLink } from "types/aliases"
import type { Sublink } from "./Dropdown"

const DURATION = 200

export default function HeaderAccordion({
	children,
	sublinks,
	industries,
}: {
	children: string
	sublinks?: Sublink[]
	industries?: IndustryLink[]
}) {
	const [isOpen, setOpen] = useState(false)

	return (
		<Root type="single" collapsible onValueChange={() => setOpen(!isOpen)}>
			<Accordion.Item value="item-1" style={{ overflow: "hidden" }}>
				<Accordion.Header>
					<Trigger>
						{children}
						<DropdownIcon
							name="chev"
							style={{
								scale: isOpen ? "1 -1" : "1 1",
							}}
						/>
					</Trigger>
					<Content>
						<ContentInner>
							{sublinks?.map((sublink) => {
								return (
									<LinkButton
										key={sublink.title}
										to={sublink.to}
										showArrow
										arrowType="right"
									>
										{sublink.title}
									</LinkButton>
								)
							})}
							{!sublinks &&
								industries?.map((industry) => {
									return (
										<LinkButton
											key={industry.industryName}
											type="button"
											showArrow
											arrowType="right"
											onClick={() => {
												loadPage(industry?.slug ?? "/", "instant").catch(
													(error: string) => {
														throw new Error(error)
													},
												)
											}}
										>
											{industry.industryName}
										</LinkButton>
									)
								})}
						</ContentInner>
					</Content>
				</Accordion.Header>
			</Accordion.Item>
		</Root>
	)
}

const slideDown = keyframes`
	from {
		height: 0;
	}
	to {
		height: var(--radix-accordion-content-height);
	}
`

const slideUp = keyframes`
	from {
		height: var(--radix-accordion-content-height);
	}
	to {
		height: 0;
	}
`

const Root = styled(Accordion.Root)`
	border-bottom: 1px solid ${colors.grey1};

	${fresponsive(css`
		margin-top: 20px;
		padding-bottom: 20px;
	`)}
`

const Trigger = styled(Accordion.Trigger)`
	${fresponsive(css`
		cursor: pointer;
		${textStyles.h4};
		text-transform: uppercase;
		color: ${colors.blue2};
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	`)}
`

const Content = styled(Accordion.Content)`
	overflow: hidden;

	&[data-state="open"] {
		animation: ${slideDown} ${DURATION}ms ${eases.cubic.out};
	}

	&[data-state="closed"] {
		animation: ${slideUp} ${DURATION}ms ${eases.cubic.in};
	}
`

const ContentInner = styled.div`
	${fresponsive(css`
		padding: 20px 2px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
	`)}
`

const DropdownIcon = styled(Icon)`
	${fresponsive(css`
		transition: scale ${DURATION}ms;
		width: 28px;
		height: auto;
		margin-bottom: 2px;
	`)}
`
