module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint_r4fczswxqevgisifpzpzrp76ju/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Response","short_name":"Response","start_url":"/","background_color":"#1F1F25","theme_color":"#1F1F25","display":"minimal-ui","icon":"./src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e9453d45c6332f49aafd505b3746eba5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.24._swnwebla4a735466jbsrbs2unu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_reac_euui4ove5wd7mnffhrifaqniea/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
