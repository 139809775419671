import { ReactComponent as ArrowSVG } from "images/arrowRight.svg"
import { ReactComponent as ArrowUpRightSVG } from "images/arrowUpRight.svg"
import { ReactComponent as ThreePLSVG } from "images/icons/3PL.svg"
import { ReactComponent as AboutSVG } from "images/icons/About.svg"
import { ReactComponent as BlogSVG } from "images/icons/Blog.svg"
import { ReactComponent as CareersSVG } from "images/icons/Careers.svg"
import { ReactComponent as ChevSVG } from "images/icons/Chev.svg"
import { ReactComponent as DistributionSVG } from "images/icons/Distribution.svg"
import { ReactComponent as PurchasingSVG } from "images/icons/Purchasing.svg"
import { ReactComponent as PlaySVG } from "images/icons/play.svg"
import styled from "styled-components"

const iconMap = {
	about: AboutSVG,
	arrow: ArrowSVG,
	arrowUpRight: ArrowUpRightSVG,
	blog: BlogSVG,
	careers: CareersSVG,
	chev: ChevSVG,
	distribution: DistributionSVG,
	purchasing: PurchasingSVG,
	threePL: ThreePLSVG,
	play: PlaySVG,
}

export type IconType = keyof typeof iconMap

export default function Icon({
	name,
	className = "",
	style = {},
	noWrapper,
}: {
	name: IconType
	className?: string
	style?: React.CSSProperties
	noWrapper?: boolean
}) {
	const Svg = iconMap[name]

	return (
		<>
			{noWrapper && <Svg className={className} style={style} />}
			{!noWrapper && (
				<Wrapper>
					<Svg className={className} style={style} />
				</Wrapper>
			)}
		</>
	)
}

const Wrapper = styled.div`
	display: grid;
	place-items: center;
`
