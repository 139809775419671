import { ReactComponent as Logo } from "images/WordmarkLogo.svg"
import BackgroundIMG from "images/footer/FooterBackground.svg"
import BarcodeIMG from "images/footer/FooterBarcode.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"

import { graphql, useStaticQuery } from "gatsby"
import Social from "./Social"
import LinkButton from "./buttons/LinkButton"
import PrimaryCTA from "./buttons/PrimaryCTA"

export default function Footer() {
	const data: Queries.FooterQuery = useStaticQuery(graphql`
		query Footer {
			allContentfulTemplatePageIndustry(
				filter : { id: { ne: "9dcc8e89-3dd0-5b26-bd8f-cbcccf621d0e" }}
			) {
				items: nodes {
					id
					industryName
					slug
				}
			}
		}
	`)

	const industries = [...data.allContentfulTemplatePageIndustry.items]

	return (
		<>
			<Wrapper>
				<MockButton to="/" ariaLabel="Response Home">
					{" "}
				</MockButton>
				<Background src={BackgroundIMG} alt="" />
				<MobileLogo to="/" ariaLabel="Response Home">
					<Logo />
				</MobileLogo>
				<Links>
					<Column>
						<Heading>Product</Heading>
						<Link to={links.signIn}>Sign In</Link>
						<Link to={links.pricing}>Pricing</Link>
						<Link to={links.faq}>FAQ</Link>
						<Link to={links.contact}>Contact</Link>
					</Column>
					{industries.length > 0 && (
						<Column>
							<Heading>Industries</Heading>
							{industries.map((industry) => (
								<Link key={industry.industryName} to={industry.slug ?? "/"}>
									{industry.industryName}
								</Link>
							))}
						</Column>
					)}
					<Column>
						<Heading>Company</Heading>
						<Link to={links.about}>About Us</Link>
						<Link to={links.careers}>Careers</Link>
						<Link to={links.blog}>Blog</Link>
					</Column>
					<Column>
						<Heading>Legal</Heading>
						<Link to={links.privacy}>Privacy</Link>
						<Link to={links.terms}>Terms</Link>
					</Column>
				</Links>
				<Call>
					<CallText>
						Response helps
						<br />
						you spend smarter
						<br />
						without working harder
					</CallText>
					<PrimaryCTA light type="calendly">
						Book a Demo
					</PrimaryCTA>
				</Call>
				<Copyright>
					© 2024 Response Inc.
					<Barcode src={BarcodeIMG} alt="" />
				</Copyright>
				<Socials>
					<Social network="twitter" />
					<Social network="instagram" />
					<Social network="facebook" />
					<Social network="linkedin" />
				</Socials>
				<Address>
					<br className="mobile" />
					+1 (844) 966-1910
					<br />
					<br className="mobile" />
					2261 MARKET STREET STE 4116
					<br />
					SAN FRANCISCO, CA 94114-1612
				</Address>
			</Wrapper>
			<Spacer />
		</>
	)
}

const Wrapper = styled.footer`
	${fresponsive(css`
		width: 1360px;
		height: 613px;
		margin: 40px auto 0;
		position: relative;
		z-index: 3;
		padding: 48px 48px 40px;
		border-radius: 0 20px 20px;
		overflow: clip;
		display: grid;
		grid-template:
			".       links   socials" auto
			"smarter smarter smarter" 1fr
			"copy    .       address" auto /
			274px 1fr auto;
	`)}

	${ftablet(css`
		width: 944px;
		grid-template:
			".       links  " auto
			"smarter smarter" 1fr
			"copy    socials" auto
			"address socials" auto /
			285px 1fr;
	`)}

	${fmobile(css`
		margin: 16px auto 0;
		background: ${colors.blue2};
		width: 342px;
		height: auto;
		border-radius: 20px;
		padding: 30px 20px 20px;
		grid-template:
			"logo" auto
			"links" auto
			"smarter" auto
			"socials" auto
			"copy" auto
			"address" auto /
			1fr;
	`)}
`

const MockButton = styled(UniversalLink)`
	${fresponsive(css`
		width: 200px;
		height: 200px;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 0 45px 45px;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const Background = styled.img`
	${fresponsive(css`
		position: absolute;
		top: 0;
		left: 0;
		width: 1360px;
		height: 100%;
		max-height: unset;
		max-width: unset;
		z-index: -1;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const MobileLogo = styled(UniversalLink)`
	grid-area: logo;
	display: none;

	${fmobile(css`
		display: block;

		svg {
			width: 130px;
		}

		* {
			fill: white;
		}
	`)}
`

const Links = styled.div`
	grid-area: links;

	${fresponsive(css`
		display: flex;
		gap: 95px;
	`)}

	${ftablet(css`
		gap: 78px;
	`)}

	${fmobile(css`
		margin: 40px 0 20px;
		display: grid;
		grid-template-rows: repeat(2, auto);
		grid-template-columns: repeat(2, auto);
		gap: 48px;
	`)}
`

const Column = styled.div`
	${fresponsive(css`
		display: flex;
		flex-direction: column;
		gap: 15px;
	`)}
`

const Heading = styled.div`
	${textStyles.sub1};
	${fresponsive(css`
		color: ${colors.blue4};
		margin-bottom: 9px;
	`)}
	${fmobile(css`
		${textStyles.sub2}
	`)}
`

const Link = styled(LinkButton).attrs({ showArrow: true })`
	color: white;

	path {
		stroke: white;
	}
`

const Call = styled.div`
	grid-area: smarter;
	border-bottom: 1px solid white;

	${fresponsive(css`
		margin-bottom: 20px;
		padding-bottom: 35px;
		display: flex;
		align-items: end;
		justify-content: space-between;
	`)}

	${fmobile(css`
		flex-direction: column;
		padding-bottom: 20px;
		margin-bottom: 24px;
		gap: 20px;
		align-items: start;
		margin-top: 54px;
	`)}
`

const CallText = styled.div`
	${textStyles.h3uncapped}
	${fresponsive(css`
		color: white;
		width: 622px;
	`)}
  ${ftablet(css`
		${textStyles.h4};
		width: 451px;
	`)}
  ${fmobile(css`
		${textStyles.h6};
		width: 223px;
	`)}
`

const Copyright = styled.div`
	grid-area: copy;
	${textStyles.p3}
	${fresponsive(css`
		align-self: center;
		display: grid;
		gap: 4px;
		color: white;
	`)}
`

const Barcode = styled.img`
	${fresponsive(css`
		width: 127px;
	`)}

	${ftablet(css`
		display: none;
	`)}

  ${fmobile(css`
		display: none;
	`)}
`

const Socials = styled.div`
	grid-area: socials;
	${fresponsive(css`
		display: flex;
		gap: 30px;
		justify-content: end;
	`)}

	${ftablet(css`
		place-self: center end;
	`)}

  ${fmobile(css`
		gap: 20px;
		padding-bottom: 24px;
		border-bottom: 1px solid white;
		margin-bottom: 24px;
	`)}
`

const Address = styled.div`
	grid-area: address;
	${textStyles.p3};
	text-align: right;
	color: white;
	line-height: 106.5%; /* 12.78px */

	& .mobile {
		display: none;
	}

	${ftablet(css`
		text-align: left;

		& .mobile {
			display: block;
		}
	`)}
	${fmobile(css`
		text-align: left;

		& .mobile {
			display: block;
		}
	`)}
`

const Spacer = styled.div`
	${fresponsive(css`
		height: 50px;
	`)}
`
