import { graphql, useStaticQuery } from "gatsby"
import gsap from "gsap"
import LogoImage from "images//WordmarkLogo.svg"
import { usePreloader } from "library/Loader/PreloaderUtils"
import UniversalLink from "library/Loader/UniversalLink"
import { DesktopOnly, TabletMobileOnly } from "library/breakpointUtils"
import { eases } from "library/eases"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import links from "utils/links"
import MobileDropdown from "../MobileDropdown"
import { preloaderDuration } from "../Preloader"
import LinkButton from "../buttons/LinkButton"
import PrimaryCTA from "../buttons/PrimaryCTA"
import Dropdown from "./Dropdown"

export default function Header() {
	const logo = useRef<HTMLImageElement>(null)
	const wrapper = useRef<HTMLDivElement>(null)
	const mobileLogo = useRef<HTMLImageElement>(null)
	const mobileWrapper = useRef<HTMLDivElement>(null)

	const data: Queries.NavQuery = useStaticQuery(graphql`
		query Nav {
			allContentfulTemplatePageIndustry(
				filter: { id: { ne: "9dcc8e89-3dd0-5b26-bd8f-cbcccf621d0e" }}
			) {
				items: nodes {
					id
					industryName
					slug
					navigationIcon
				}
			}
		}
	`)

	const industries = [...data.allContentfulTemplatePageIndustry.items]

	const ResourcesSublinks = [
		{
			icon: "about",
			to: links.about,
			title: "About",
		},
		{
			icon: "careers",
			to: links.careers,
			title: "Careers",
		},
		{
			icon: "blog",
			to: links.blog,
			title: "Blog",
		},
	]

	/**
	 * sync logo with the preloader
	 */
	useAnimation(() => {
		gsap.to([logo.current, mobileLogo.current], {
			delay: preloaderDuration,
		})
	}, [])

	/**
	 * animate in the buttons
	 */
	useEffect(() => {
		if (!wrapper.current) return
		if (!mobileWrapper.current) return

		const elementsToAnimate = [
			...[...wrapper.current.children],
			...[...mobileWrapper.current.children],
		]

		elementsToAnimate.forEach((element) => {
			element.classList.add("before-slide-in")
		})
	}, [])

	usePreloader({
		critical: true,
		callback: () => {
			if (!wrapper.current) return
			if (!mobileWrapper.current) return

			const elementsToAnimate = [
				...[...wrapper.current.children],
				...[...mobileWrapper.current.children],
			]

			elementsToAnimate.forEach((element, index) => {
				setTimeout(
					() => {
						element.classList.add("slide-in")
						element.classList.remove("before-slide-in")
					},
					index * 100 + 500,
				)
			})
		},
		duration: 1,
	})

	return (
		<>
			<DesktopOnly>
				<Wrapper ref={wrapper}>
					<UniversalLink to="/" ariaLabel="Response Home">
						<Logo src={LogoImage} alt="Response Logo" ref={logo} />
					</UniversalLink>
					{industries.length > 0 && (
						<Dropdown industries={industries}>Industries</Dropdown>
					)}
					<LinkButton showArrow to={links.pricing}>
						Pricing
					</LinkButton>
					<Dropdown sublinks={ResourcesSublinks}>Resources</Dropdown>
					<LinkButton showArrow to={links.signIn}>
						Sign In
					</LinkButton>
					<div>
						<StyledPrimaryCTA type="calendly">Book a Demo</StyledPrimaryCTA>
					</div>
				</Wrapper>
			</DesktopOnly>
			<TabletMobileOnly>
				<Wrapper ref={mobileWrapper}>
					<UniversalLink to="/" ariaLabel="Response Home">
						<Logo src={LogoImage} alt="Response Logo" ref={mobileLogo} />
					</UniversalLink>
					<div />
					<MobileDropdown
						industries={industries}
						sublinks={ResourcesSublinks}
					/>
				</Wrapper>
			</TabletMobileOnly>
		</>
	)
}

/**
 * animating this with GSAP can cause pop-in on some android devices,
 * where the children will not render as they animate in
 */
const slideIn = keyframes`
  0% { translate: 0 -20vw; opacity: 0 }
  0.1% { opacity: 1 }
  100% { translate: 0 0 }
`

const Wrapper = styled.div`
	position: relative;
	z-index: 102;

	.before-slide-in {
		opacity: 0;
	}

	.slide-in {
		animation: ${slideIn} 1s ${eases.cubic.out};
	}

	${fresponsive(css`
		display: flex;
		gap: 80px;
		align-items: center;
		padding: 24px 0;
		width: 1360px;
		margin: 0 auto;
	`)}

	${ftablet(css`
		width: 944px;

		& > *:last-child {
			margin-left: auto;
		}
	`)}

  ${fmobile(css`
		width: 335px;
		gap: 0;
	`)}

  & > *:first-child {
		margin-right: auto;
	}
`

const Logo = styled.img`
	${fresponsive(css`
		width: 128px;
		max-width: none;
	`)}
`

const StyledPrimaryCTA = styled(PrimaryCTA)`
	${fresponsive(css`
		padding: 25px 45px;
	`)}
`
