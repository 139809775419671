import { useClientOnly } from "library/ClientOnly"
import type { UniversalLinkProps } from "library/Loader/UniversalLink"
import UniversalLink from "library/Loader/UniversalLink"
import { isBrowser } from "library/deviceDetection"
import { PopupButton } from "react-calendly"
import styled from "styled-components"
import links from "utils/links"

const getRoot = () =>
	isBrowser ? document.querySelectorAll("body")[0] : undefined

export type CalendlyProps =
	| UniversalLinkProps
	| { type: "calendly"; children: React.ReactNode; className?: string }

export default function CalendlyButton(props: CalendlyProps) {
	const root = useClientOnly(getRoot())

	if (props.type === "calendly") {
		return root ? (
			<Button
				url={links.bookADemo}
				rootElement={root}
				// this wants a string but can actually take any react node
				text={props.children as string}
				{...props}
			/>
		) : (
			<button type="button">{props.children}</button>
		)
	}

	return <UniversalLink {...props} />
}

const Button = styled(PopupButton)`
	cursor: pointer;
`
