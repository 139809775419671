import gsap from "gsap"
import { ReactComponent as BarcodeSVG } from "images/Barcode.svg"
import AutoAnimate from "library/AutoAnimate"
import { loader } from "library/Loader"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { fresponsive } from "library/fullyResponsive"
import useAnimation from "library/useAnimation"
import { useRef, useState } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export const preloaderDuration = 1.5

export default function Preloader() {
	const wrapper = useRef<HTMLDivElement>(null)
	const barcode = useRef<HTMLDivElement>(null)

	useAnimation(
		() => {
			gsap.to(barcode.current, {
				opacity: 1,
				ease: "power1.out",
			})
		},
		[],
		{
			kill: true,
		},
	)

	usePreloader({
		critical: true,
		callback: () => {
			gsap.to(wrapper.current, { backgroundColor: "transparent" })
			gsap.to(barcode.current, {
				y: "-100vh",
				duration: 1,
				ease: "power2.inOut",
			})
		},
		duration: 0,
	})

	/**
	 * track loader progress
	 */
	const [progress, setProgress] = useState(0)
	loader.useEventListener("progressUpdated", setProgress)

	return (
		<Wrapper ref={wrapper}>
			<Loader ref={barcode}>
				<LightBarcode />
				<DarkBarcode
					style={{
						clipPath: `polygon(0 0, ${progress}% 0, ${progress}% 100%, 0 100%)`,
					}}
				/>
				<Text>
					<div>Loading</div>
					<AutoAnimate duration={0.2}>
						{Math.round(progress).toString().padStart(2, "0")}
					</AutoAnimate>
					%
				</Text>
			</Loader>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	${fresponsive(css`
		pointer-events: none;
		background-color: ${colors.alpine};
		position: fixed;
		inset: 0;
		z-index: 999;
		display: grid;
		place-items: center;
	`)}
`

const Loader = styled.div`
	width: min-content;
	margin: 0 auto;
	opacity: 0;
`

const LightBarcode = styled(BarcodeSVG)`
	${fresponsive(css`
		height: 43px;
		margin-bottom: -43px;
		display: block;

		path {
			fill: ${colors.grey1};
		}
	`)}
`

const DarkBarcode = styled(LightBarcode)`
	margin-bottom: 0;
	transition: clip-path 0.2s ease-out;

	path {
		fill: ${colors.blue2};
	}
`

const Text = styled.div`
	${textStyles.kicker1};

	${fresponsive(css`
		display: grid;
		grid-template-columns: 1fr auto auto;
		margin-top: 10px;
	`)}
`
