import * as Popover from "@radix-ui/react-popover"
import Icon, { type IconType } from "components/Icon"
import { loadPage } from "library/Loader/TransitionUtils"
import UniversalLink from "library/Loader/UniversalLink"
import { useScrollLock } from "library/Scroll"
import { eases } from "library/eases"
import { fresponsive } from "library/fullyResponsive"
import { useBetterThrottle } from "library/useBetterThrottle"
import { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"
import type { IndustryLink } from "types/aliases"

const DURATION = 200

export type Sublink = {
	icon: string
	to: string
	title: string
}

export default function Dropdown({
	children,
	sublinks,
	industries,
}: {
	children: string
	sublinks?: Sublink[]
	industries?: IndustryLink[]
}) {
	const [rawOpen, setOpen] = useState(false)
	// throttle the state change to allow the animation to finish
	const open = useBetterThrottle(rawOpen, DURATION)
	// lock the scroll when the dropdown is open
	useScrollLock("lock", open)

	return (
		<div onMouseLeave={() => setOpen(false)} onMouseEnter={() => setOpen(true)}>
			<Blur style={{ opacity: open ? 1 : 0 }} />
			<Root open={open} onOpenChange={setOpen}>
				<Anchor>
					<Popover.Trigger asChild>
						<DropdownTitle>
							<DropdownIcon
								name="chev"
								style={{
									scale: open ? "1 -1" : "1 1",
								}}
							/>
							{children}
						</DropdownTitle>
					</Popover.Trigger>
				</Anchor>
				<Content
					align="start"
					style={{
						// prevent 'bouncing' when mouse is positioned below
						pointerEvents: open ? "auto" : "none",
					}}
				>
					<Column>
						{sublinks?.map((sublink) => {
							return (
								<SubLink key={sublink.title}>
									<Icon name={sublink.icon as IconType} />
									<StyledLink to={sublink.to}>
										<SublinkTitle>{sublink.title}</SublinkTitle>
										<StyledIcon name="arrowUpRight" />
									</StyledLink>
								</SubLink>
							)
						})}
						{!sublinks &&
							industries?.map((industry) => {
								return (
									<SubLink key={industry?.industryName}>
										<Icon name={industry?.navigationIcon as IconType} />
										<StyledLink
											type="button"
											onClick={() => {
												loadPage(industry?.slug ?? "/", "instant").catch(
													(error: string) => {
														throw new Error(error)
													},
												)
											}}
										>
											<SublinkTitle>{industry?.industryName}</SublinkTitle>
											<StyledIcon name="arrowUpRight" />
										</StyledLink>
									</SubLink>
								)
							})}
					</Column>
				</Content>
			</Root>
		</div>
	)
}

const disableRadixAccessibility = css`
	outline: none !important;
	border: none !important;
`

const Root = styled(Popover.Root)`
	${disableRadixAccessibility};
`

const StyledLink = styled(UniversalLink)`
	${disableRadixAccessibility};

	${fresponsive(css`
		color: ${colors.grey2};
		${textStyles.button1medium};
		display: flex;
		gap: 6px;
		align-items: center;
		transition: all ${DURATION}ms;
	`)}
`

const SublinkTitle = styled.div`
	${disableRadixAccessibility};
	position: relative;
	z-index: 2;
`

const DropdownIcon = styled(Icon)`
	${fresponsive(css`
		transition: scale ${DURATION}ms;
		width: 12px;
		height: auto;
	`)}
`

const DropdownTitle = styled.div`
	${fresponsive(css`
		display: flex;
		gap: 6px;
		align-items: center;
		color: ${colors.blue2};
		${textStyles.button1medium};
	`)}
`

const slideIn = keyframes`
	from {
		translate: 0 100%;
		opacity: 0;
	}
	to {
		translate: 0 0;
		opacity: 1;
	}
`

const slideOut = keyframes`
	from {
		translate: 0 0;
		opacity: 1;
	}
	to {
		translate: 0 100%;
		opacity: 0;
	}
`

const Anchor = styled(Popover.Anchor)`
	${fresponsive(css`
		padding-bottom: 6px;
		margin-bottom: -6px;
	`)}
`

const Blur = styled.div`
	position: absolute;
	width: 100vw;
	height: 150vh;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background: rgba(219 219 219 / 15%);
	backdrop-filter: blur(7px);
	pointer-events: none;
	z-index: -1;
	transition: opacity ${DURATION}ms;
`

const Content = styled(Popover.Content)`
	${disableRadixAccessibility};
	border: 1px solid ${colors.grey1} !important;
	background: ${colors.alpine};

	${fresponsive(css`
		box-shadow: 0 15px 45px 0 rgba(0 0 0 / 8%);
		padding: 24px;
		border-radius: 10px;
		width: 225px;
		margin-top: 15px;
		position: relative;
		left: -33px;
	`)}

	&[data-state="open"] {
		animation: ${slideIn} ${DURATION}ms ${eases.cubic.out};
	}

	&[data-state="closed"] {
		animation: ${slideOut} ${DURATION}ms ${eases.cubic.in};
	}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 12px;
	`)}
`

const StyledIcon = styled(Icon)`
	transition: 0.5s cubic-bezier(0.49, 0, 0.22, 1);
	transition-property: translate, opacity;
	translate: -100% 0;
	position: relative;
	z-index: 1;

	${fresponsive(css`
		translate: "-150% 3px";
		width: 11px !important;
		height: auto !important;
		opacity: 0;
	`)}

	path {
		stroke: ${colors.blue2};
	}
`

const SubLink = styled.div`
	${disableRadixAccessibility};
	display: flex;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid ${colors.grey1} !important;
	cursor: pointer;

	${fresponsive(css`
		gap: 12px;
		padding-bottom: 12px;

		&:last-of-type {
			padding-bottom: 0;
			border-bottom: unset !important;
		}

		svg {
			width: 36px;
			height: auto;
		}
	`)}

	&:hover {
		${StyledLink} {
			color: ${colors.blue2};
		}

		${StyledIcon} {
			translate: 0 0;
			opacity: 1;
		}
	}
`
