import { fresponsive } from "library/fullyResponsive"
import { css } from "styled-components"

import media from "./media"

const textStyles = {
	h1: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-1 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 142px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 127.8px */
		letter-spacing: -3.55px;
		text-transform: uppercase;
	`),
	h2: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-2 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 94px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 84.6px */
		letter-spacing: -2.35px;
		text-transform: uppercase;
	`),
	h2uncapped: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-2 (Uncapped) */
		font-family: "PP Telegraf", sans-serif;
		font-size: 94px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 84.6px */
		letter-spacing: -2.82px;
	`),
	h3: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-3 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 54px */
		letter-spacing: -1.5px;
		text-transform: uppercase;
	`),
	h3uncapped: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-3 (Uncapped) */
		font-family: "PP Telegraf", sans-serif;
		font-size: 60px;
		font-style: normal;
		font-weight: 400;
		line-height: 90%; /* 54px */
		letter-spacing: -1.5px;
	`),
	h4: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-4 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 40px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 40px */
		letter-spacing: -1px;

		${media.mobile} {
			font-weight: 500;
			line-height: 90%; /* 36px */
		}
	`),
	h5: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-5 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 32px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 32px */
		letter-spacing: -0.8px;
	`),
	h6: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Headers/H-6 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 20px */
		letter-spacing: -0.5px;
	`),
	sub1: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Sub-Header/SH-1 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 24px */
		letter-spacing: -0.5px;
	`),
	sub2: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Sub-Header/SH-2 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 19.2px */
		letter-spacing: -0.4px;
	`),
	sub3: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Sub-Header/SH-3 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 14.4px */
		letter-spacing: -0.3px;
	`),
	kicker1: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Kickers/K-1 */
		font-family: "PP Fraktion Mono", monospace;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: -0.4px;
		text-transform: uppercase;
	`),
	kicker2: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Kickers/K-2 */
		font-family: "PP Fraktion Mono", monospace;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
		letter-spacing: -0.3px;
		text-transform: uppercase;
	`),
	kicker3: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Kickers/K-2 */
		font-family: "PP Fraktion Mono", monospace;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
		letter-spacing: -0.25px;
		text-transform: uppercase;
	`),
	button1bold: fresponsive(css`
		/* Buttons/B-1 (Bold) */
		font-family: "PP Telegraf", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 16px */
		letter-spacing: -0.64px;
	`),
	button1medium: fresponsive(css`
		/* Buttons/B-1 (Medium) */
		font-family: "PP Telegraf", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 16px */
		letter-spacing: -0.64px;
	`),
	button2medium: fresponsive(css`
		/* Buttons/B-2(Medium) */
		font-family: "PP Telegraf", sans-serif;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 14px */
		letter-spacing: -0.56px;
	`),
	p1: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Paragraph/P-1 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 24px */
		letter-spacing: -0.5px;
	`),
	p2: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Paragraph/P-2 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%; /* 20.8px */
		letter-spacing: -0.4px;
	`),
	p3: fresponsive(css`
		font-feature-settings:
			"clig" off,
			"liga" off;

		/* Paragraph/P-3 */
		font-family: "PP Telegraf", sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%; /* 18px */
		letter-spacing: 0.24px;
	`),
}

export const strokeText = css`
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	background-size: 100%;
	background-clip: text;
	-webkit-text-stroke-width: 0.07vw;
`

export default textStyles
