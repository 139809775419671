import facebook from "images/icons/Facebook.svg"
import instagram from "images/icons/Instagram.svg"
import linkedin from "images/icons/LinkedIn.svg"
import twitter from "images/icons/Twitter.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import links from "utils/links"

const icons = {
	facebook,
	instagram,
	linkedin,
	twitter,
}

export default function Social({
	className,
	network,
}: {
	className?: string
	network: "twitter" | "facebook" | "linkedin" | "instagram"
}) {
	return (
		<Link to={links[network]} className={className}>
			<Icon src={icons[network]} alt={network} />
		</Link>
	)
}
const Link = styled(UniversalLink)`
	${fresponsive(css`
		width: 18px;
		height: 18px;
	`)}
`

const Icon = styled.img`
	${fresponsive(css`
		width: 18px;
		height: 18px;
	`)}
`
